import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { typo } from 'carbonlite/styles/fonts'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Head from './Head'
import LargeCopy from './LargeCopy'
import Button from './Button'

import products from 'carbonlite/data/products'

const Products = () => (
  <>
    <Head
      title="Products — Carbonlite Converting Equipment Ltd"
      description={ products.intro }
    />

    <LayoutSection fixedHeaderPush narrow>
      <VerticalStack large>
        <LargeCopy>{ products.title }</LargeCopy>
      </VerticalStack>
    </LayoutSection>

    <LayoutSection white pushLeft>
      <VerticalStack hero top>
        { products.products.map( ( product, i ) => <Product product={ product } key={ i } /> ) }
      </VerticalStack>
    </LayoutSection>
  </>
)

export default Products

const Product = ( { product } ) => (
  <StyledProductListItem>
    <Link to={ `/products/${ product.slug }` }>
      <StyledProductListItemInfo>
        <h2>{ product.title }</h2>
        <p>{ product.intro }</p>
        <div>
          <Button icon="arrowRight" iconPosition="right">More</Button>
        </div>
      </StyledProductListItemInfo>
      { product.img && (
        <StyledProductListItemImage>
          <img src={ product.img } alt="" />
        </StyledProductListItemImage>
      ) }
    </Link>
  </StyledProductListItem>
)

const StyledProductListItem = styled.div`
  a {
    display: block;
    padding: 2rem 0;
    text-decoration: none;
    color: var( --darkBlue );

    @media all and ( min-width: 600px ) {
      display: flex;
    }
  }

  border-bottom: 1px solid rgba( var( --footerBgRgb ), 0.3 );

  &:first-child a {
    padding-top: 0;
  }
`

const StyledProductListItemInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media all and ( min-width: 600px ) {
    padding-right: 3rem;
  }

  h2 {
    ${ typo.heading }
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    max-width: 800px;
  }

  > div {
    margin-top: auto;
  }
`

const StyledProductListItemImage = styled.div`
  flex-basis: 260px;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    max-width: 300px;
    /* filter: grayscale( 100% ); */
  }

  @media all and ( max-width: 599px ) {
    margin-top: 2rem;
  }

  @media all and ( max-width: 800px ) {
    flex-basis: 160px;
  }
`