const applications = {
  title: 'Applications',
  intro: 'Carbonlite machines are being applied in the following areas:',
  img: '/images/machine-3s.jpg',
  lists: [
    {
      title: 'Packaging films and foils',
      items: [
        'Hot stamping foils',
        'Holographic films',
        'Barrier coatings',
      ],
    },
    {
      title: 'Medical',
      items: [
        'Diagnostic test strips',
        'Micro fluidics',
      ],
    },
    {
      title: 'Flexible Electronics',
      items: [
        'Printable electronics',
        'OLED',
      ],
    },
    {
      title: 'Optical',
      items: [
        'Micro lens arrays',
        'Gratings',
        'Anti reflection coatings',
        'HRI coatings',
      ],
    },
    {
      title: 'Energy',
      items: [
        'Fuel cell menbranes',
        'Thin film flexible solar cells',
        'Electrodes, Li ion batteries',
      ],
    },
  ],
}

export default applications