import React from 'react'

const Icon = ( { iconName, iconTitle } ) => {
  const icons = {
    burger: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24">
        <title>{ iconTitle || `burger` }</title>
        <rect className="fw-shape" width="22" height="4.32"/>
        <rect className="fw-shape" y="9.83" width="22" height="4.32"/>
        <rect className="fw-shape" y="19.91" width="22" height="4.32"/>
      </svg>
    ),

    arrowRight: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 70">
        <title>{ iconTitle || `Arrow Right` }</title>
        {/* <path d="M67.34,15.9a3.55,3.55,0,1,0-5,5L87.85,46.44H3.53a3.56,3.56,0,0,0,0,7.11H87.85L62.3,79.06a3.62,3.62,0,0,0,0,5,3.53,3.53,0,0,0,5,0l31.6-31.6a3.47,3.47,0,0,0,0-5Z"/> */}
        <path d="M67.3,1.3c-1.3-1.5-3.5-1.7-5-0.5s-1.7,3.5-0.5,5C62,6,62.1,6.1,62.3,6.3l25.6,25.5H3.4c-2,0.1-3.5,1.8-3.4,3.7c0.1,1.8,1.6,3.3,3.4,3.4h84.5L62.3,64.4c-1.3,1.4-1.3,3.6,0,5c1.4,1.4,3.6,1.4,5,0c0,0,0,0,0,0l31.7-31.6c1.4-1.3,1.4-3.5,0.1-4.9c0,0-0.1-0.1-0.1-0.1L67.3,1.3z"/>
      </svg>
    ),

    email: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 11.25">
        <title>{ iconTitle || `Email` }</title>
        <path d="M-1712.5-1703.44l-7.5-5.62a.94.94,0,0,1,.94-.94h13.13a.93.93,0,0,1,.93.94Zm0,1.69,7.5-5.63v7.69a.92.92,0,0,1-.93.94h-13.13a.93.93,0,0,1-.94-.94v-7.69Z" transform="translate(1720 1710)"/>
      </svg>
    ),

    facebook: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.5 16.99">
        <title>{ iconTitle || `Facebook` }</title>
        <path d="M2.12,9.21H0V6.37H2.12V3.89C2.12,1.89,3.38,0,6.37,0A14.06,14.06,0,0,1,8.5.16L8.38,2.83H6.55c-1.08,0-1.24.59-1.24,1.42V6.37H8.5L8.28,9.21h-3V17H2.12Z"/>
      </svg>
    ),

    github: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.51">
        <title>{ iconTitle || `Github` }</title>
        <path d="M10,0A10,10,0,0,0,6.84,19.49c.5.09.68-.22.68-.48s0-.87,0-1.7C4.73,17.91,4.14,16,4.14,16A2.65,2.65,0,0,0,3,14.5c-.91-.62.07-.61.07-.61a2.1,2.1,0,0,1,1.53,1,2.13,2.13,0,0,0,2.91.83,2.14,2.14,0,0,1,.63-1.34C6,14.17,3.62,13.31,3.62,9.48a3.87,3.87,0,0,1,1-2.68,3.6,3.6,0,0,1,.1-2.65s.84-.27,2.75,1a9.48,9.48,0,0,1,5,0c1.91-1.29,2.75-1,2.75-1a3.59,3.59,0,0,1,.1,2.65,3.86,3.86,0,0,1,1,2.68c0,3.84-2.34,4.69-4.57,4.93a2.39,2.39,0,0,1,.68,1.85c0,1.34,0,2.42,0,2.74s.18.58.69.48A10,10,0,0,0,10,0Z"/>
      </svg>
    ),

    instagram: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.99 16.99">
        <title>{ iconTitle || `Instragram` }</title>
        <path d="M17,5.31H11.82a4.61,4.61,0,0,0-6.65,0H0V2.48A2.49,2.49,0,0,1,2.48,0h12A2.49,2.49,0,0,1,17,2.48Zm0,1.42v7.79A2.49,2.49,0,0,1,14.52,17h-12A2.49,2.49,0,0,1,0,14.52V6.73H4.25A4.44,4.44,0,0,0,3.89,8.5a4.6,4.6,0,1,0,9.2,0,4.44,4.44,0,0,0-.35-1.77ZM5.31,8.5A3.19,3.19,0,1,1,8.5,11.68,3.19,3.19,0,0,1,5.31,8.5Z"/>
      </svg>
    ),

    mapMarker: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 33">
        <title>{ iconTitle || `Marker` }</title>
        <path fill="#F7323F" d="M12,0.5c6.6,0,11.9,5.3,11.9,11.9S18.6,24.3,12,24.3S0.1,19,0.1,12.4S5.4,0.5,12,0.5"/>
        <polygon fill="#F7323F" points="21.8,19.3 12,33 2.3,19.3 "/>
      </svg>
    ),

    twitter: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.99 13.81">
        <title>{ iconTitle || `Twitter` }</title>
        <path d="M15.26,3.44v.45a9.84,9.84,0,0,1-9.91,9.91A9.81,9.81,0,0,1,0,12.39a5.8,5.8,0,0,0,.83.06A7.05,7.05,0,0,0,5.15,10.8,3.48,3.48,0,0,1,1.91,8.38a3.65,3.65,0,0,0,1.57-.06A3.48,3.48,0,0,1,.69,4.9v0a3.55,3.55,0,0,0,1.57.43A3.47,3.47,0,0,1,.71,2.4,3.26,3.26,0,0,1,1.18.71,9.91,9.91,0,0,0,8.36,4.29a3.59,3.59,0,0,1-.08-.81A3.48,3.48,0,0,1,11.76,0a3.57,3.57,0,0,1,2.56,1.1,7.11,7.11,0,0,0,2.2-.85A3.47,3.47,0,0,1,15,2.18a6.88,6.88,0,0,0,2-.55A7.21,7.21,0,0,1,15.26,3.44Z"/>
      </svg>
    ),
  }

  return icons[ iconName ]
}

export default Icon
