const processDevelopment = {
  title: 'Process Development',
  intro: 'Carbonlite can provide a comprehensive process development service for coatings and microstructure replication. Our staff scientists and engineers have the following equipment available to them:',
  img: '/images/console-1s.jpg',
  lists: [
    {
      title: null,
      items: [
        'Carbonlite 300mm combined coating, laminating and UV embossing machine',
        'Carbonlite 300mm vacuum web coating machine',
        'Carbonlite 100mm vacuum web coating machine',
        'Edwards 306 vacuum coating machine',
        'SEM',
      ],
    },
    {
      title: 'Previous projects (non confidential) include:',
      items: [
        'Signature control layers, active and passive',
        'Redirectional coatings',
        'Solar control coatings',
        'Barrier coating development',
        'Continuous nano particle fabrication',
        'Release layer coatings',
        'Organic vacuum depostion',
        'Structured coatings for flexible electonics',
      ],
    },
  ],
}

export default processDevelopment