import React from 'react'
import { Helmet } from 'react-helmet'

const Head = ( {
  title,
  description,
  img,
  url,
  siteTitle = 'Carbonlite Converting Equipment Ltd',
  twitterCard = 'summary_large_image',
  // twitterSite = '@madebyfieldwork',
} ) => {
  const titleText = title
    ? title.replace( /<(?:.|\n)*?>/gm, '' )
    : ''
  const descriptionText = description
    ? description.replace( /<(?:.|\n)*?>/gm, '' )
    : null

  return (
    <>
      <Helmet
        defaultTitle={ titleText }
      >
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#163438" />
        <meta name="msapplication-TileColor" content="#3deee7" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content={ titleText } />
        <meta name="twitter:title" content={ titleText } />
        <meta property="og:title" content={ titleText } />

        {/* <meta name="twitter:site" content={ twitterSite } /> */}

        <meta property="og:url" content={ url } />
        <meta property="og:site_name" content={ siteTitle } />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
      </Helmet>

      { description
        ? (
          <Helmet>
            <meta name="description" content={ descriptionText } />
            <meta itemprop="description" content={ descriptionText } />
            <meta name="twitter:description" content={ descriptionText } />
            <meta property="og:description" content={ descriptionText } />
          </Helmet>
        )
        : null
      }

      { img && img.social
        ? (
          <Helmet>
            <meta name="image" content={ img.social } />
            <meta itemprop="image" content={ img.social } />
            <meta property="og:image" content={ img.social } />
            <meta name="twitter:card" content={ twitterCard } />
            <meta name="twitter:image:src" content={ img.social } />
          </Helmet>
        )
        : null
      }

      { img && img.altText
        ? (
          <Helmet>
            <meta property="og:image:alt" content={ img.altText } />
            <meta name="twitter:image:alt" content={ img.altText } />
          </Helmet>
        )
        : null
      }
    </>
  )
}

export default Head