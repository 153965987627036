import React from 'react'
import styled, { css } from 'styled-components'
import { typo } from 'carbonlite/styles/fonts'

const LargeCopy = ( { html, children, light } ) => {
  if ( html ) {
    return (
      <StyledLargeCopy
        dangerouslySetInnerHTML={ { __html: html } }
        light={ light }
      />
    )
  }

  return (
    <StyledLargeCopy light={ light }>
      { children }
    </StyledLargeCopy>
  )
}

const StyledLargeCopy = styled.div`
  ${ typo.large }

  ${ p => p.light && css`
    font-weight: 300;
  ` }

  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default LargeCopy