const carbonlite = {
  address: `Carbonlite Converting Equipment Ltd
  1 Hudson's Passage
  Littleborough
  OL15 9EY
  England`,
  email: 'sales@ccequipment.co.uk',
  phone: '01706 508321',
}

export default carbonlite