import React from 'react'
import styled, { css } from 'styled-components'
import nl2br from 'react-nl2br'
import { typo } from 'carbonlite/styles/fonts'
import LayoutSection from './LayoutSection'

const Footer = ( { carbonlite } ) => {
  return (
    <StyledFooter>
      <LayoutSection>
        <StyledFooterGrid>
          <StyledFooterPanel>
            <StyledFooterPanelHeading>
              Address
            </StyledFooterPanelHeading>
            <StyledFooterPanelBody>
              { nl2br( carbonlite.address ) }
            </StyledFooterPanelBody>
          </StyledFooterPanel>

          <StyledFooterPanel>
            <StyledFooterPanelHeading>
              Contact Us
            </StyledFooterPanelHeading>
            <StyledFooterPanelBody>
              <a href={ `mailto:${ carbonlite.email }` }>{ carbonlite.email }</a><br />
              { carbonlite.phone ? <a href={ `tel:${ carbonlite.phone }` }>{ carbonlite.phone }</a> : null }
            </StyledFooterPanelBody>
          </StyledFooterPanel>

        </StyledFooterGrid>
      </LayoutSection>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  padding: 2rem 0;
  background: var( --footerBg );
`

const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;

  @media all and ( min-width: 500px ) {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledFooterPanel = styled.div`
  padding-bottom: 2em;

  ${ p => p.gridColumn && css`
    grid-column: ${ p.gridColumn };
  ` }
`

const StyledFooterPanelHeading = styled.div`
  ${ typo.heading }
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var( --highlightColor );
`

const StyledFooterPanelBody = styled.div`
  font-size: 14px;
  line-height: 1.42;
  color: var( --darkBlue );

  a {
    display: inline-block;
    margin-bottom: 0.3em;
    color: var( --darkBlue );
    border-bottom: 1px solid rgba( var( --darkBlueRgb ), 0.3);
    text-decoration: none;
    transition: border-color .3s ease-out;
  }
`

export default Footer