import React from 'react'
import styled, { css } from 'styled-components'

const VerticalStack = ( props ) => {
  const bottom = typeof props.bottom === 'undefined'
    ? true
    : props.bottom

  return <StyledVerticalStack { ...props } bottom={ bottom } />
}

const StyledVerticalStack = styled.div`
  ${ p => p.top && css`
    padding-top: 2rem;
  ` }

  ${ p => p.bottom && css`
    padding-bottom: 2rem;
  ` }

  ${ p => p.notLastChild && css`
    &:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  ` }

  ${ p => p.none && css`
    padding-top: 0;
    padding-bottom: 0;
  ` }

  ${ p => p.small && css`
    ${ p => p.top && css`
      padding-top: 1rem;
    ` }

    ${ p => p.bottom && css`
      padding-bottom: 1rem;
    ` }
  ` }

  ${ p => p.large && css`
    ${ p => p.top && css`
      padding-top: 4rem;
    ` }

    ${ p => p.bottom && css`
      padding-bottom: 4rem;
    ` }
  ` }

  ${ p => p.xlarge && css`
    ${ p => p.top && css`
      padding-top: 6rem;
    ` }

    ${ p => p.bottom && css`
      padding-bottom: 6rem;
    ` }
  ` }

  ${ p => p.hero && css`
    ${ p => p.top && css`
      padding-top: 3rem;
    ` }

    ${ p => p.bottom && css`
      padding-bottom: 3rem;
    ` }

    @media all and ( min-width: 600px ) {
      ${ p => p.top && css`
        padding-top: 5rem;
      ` }

      ${ p => p.bottom && css`
        padding-bottom: 5rem;
      ` }
    }
  ` }

  ${ p => p.fixedHeaderPush && css`
    padding-top: 120px;
  ` }
`

export default VerticalStack