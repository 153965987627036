import React from 'react'
import { Switch, Route } from 'react-router'
import styled from 'styled-components'
import GlobalStyles from 'carbonlite/styles/GlobalStyles'
import Head from './Head'
import Header from './Header'
import Footer from './Footer'
// import HomeWithData from './HomeWithData'
// import ProjectWithData from './ProjectWithData'
// import ServiceWithData from './ServiceWithData'
// import AboutWithData from './AboutWithData'
// import ContactWithData from './ContactWithData'
// import ServicesWithData from './ServicesWithData'
// import ProjectsWithData from './ProjectsWithData'
// import NotFoundWithData from './NotFoundWithData'
import Home from './Home'
import Products from './Products'
import Product from './Product'
import Applications from './Applications'
import ProcessDevelopment from './ProcessDevelopment'
import Contact from './Contact'
import NotFound from './NotFound'

import carbonlite from 'carbonlite/data/carbonlite'

const App = ( { fieldwork, highlightColors } ) => (
  <StyledApp>
    <Head
      title="Carbonlite Converting Equipment Ltd — Carbonlite is a leading supplier of reel to reel narrow web coating, laminating and UV embossing machines."
      url="https://carbonlite.co.uk"
    />
    <GlobalStyles highlightColors={ highlightColors } />
    <Header />
    <StyledContentArea>
      <Switch>
        {/* <Route exact path="/" component={ HomeWithData } />
        <Route exact path="/about" component={ AboutWithData } />
        <Route exact path="/contact" component={ ContactWithData } />
        <Route exact path="/services" component={ ServicesWithData } />
        <Route exact path="/projects" component={ ProjectsWithData } />
        <Route path="/projects/:projectSlug" component={ ProjectWithData } />
        <Route path="/services/:serviceSlug" component={ ServiceWithData } /> */}
        {/* <Route component={ NotFoundWithData } /> */}
        <Route exact path="/" component={ Home } />
        <Route exact path="/products" component={ Products } />
        <Route exact path="/products/:slug" component={ Product } />
        <Route exact path="/applications" component={ Applications } />
        <Route exact path="/process-development" component={ ProcessDevelopment } />
        <Route exact path="/contact" component={ Contact } />
        <Route component={ NotFound } />
      </Switch>
    </StyledContentArea>
    <StyledFooterArea>
      <Footer carbonlite={ carbonlite } />
    </StyledFooterArea>
  </StyledApp>
)

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledContentArea = styled.div`
  flex: 1 0 auto;
`

const StyledFooterArea = styled.div`
  flex-shrink: 0;
`

export default App
