import React from 'react'
import styled from 'styled-components'
import { typo } from 'carbonlite/styles/fonts'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Head from './Head'
import LargeCopy from './LargeCopy'

import home from 'carbonlite/data/home'

const Home = () => (
  <>
    <Head
      title="Carbonlite Converting Equipment Ltd"
      description={ home.intro }
    />
    <LayoutSection fixedHeaderPush narrow>
      <VerticalStack hero>
        <LargeCopy>{ home.intro }</LargeCopy>
      </VerticalStack>
    </LayoutSection>

    <FancyImageCard
      img={ `test` }
      pull={ [
        'We have supplied machines worldwide since 1979, accumulating an extensive knowledge of coating, laminating and embossing processes which are available to our users.',
      ] }
    >
      <p>Machines can be supplied for in air and in vaccum web coating products providing unique process capabilities for our users.</p>
      <p>Our machines are completely modular so that they can be easily customized to suit our users’ specifications. The modular design also means that the machines can be reconfigured or extended to cope with our users’ future requirements.</p>
    </FancyImageCard>
  </>
)

export default Home

const FancyImageCard = ( { children, pull, pull2 } ) => (
  <StyledFancyImageCard>
    <StyledFancyImageCardImage>
      <img src="/images/machine-1s.jpg" alt="" />
    </StyledFancyImageCardImage>
    <StyledFancyImageCardContent>
      { pull.map( ( p, i ) => <p key={ i }>{ p }</p> ) }
      { children }
    </StyledFancyImageCardContent>
  </StyledFancyImageCard>
)

const StyledFancyImageCard = styled.div`
  position: relative;

  @media all and ( min-width: 901px ) and ( max-width: 1149px ) {
    margin-top: 2rem;
  }

  @media all and ( min-width: 901px ) {
    display: flex;
  }
`

const StyledFancyImageCardImage = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  background: var( --vibrantBlue );

  @media all and ( min-width: 1150px ) {
    flex-basis: 66.66%;
  }

  img {
    display: block;
    width: 100%;
    filter: grayscale( 100% );
    mix-blend-mode: multiply;
  }
`

const StyledFancyImageCardContent = styled.div`
  flex-grow: 1;
  padding: 2rem 5vw;
  background: white;

  @media all and ( min-width: 900px ) {
    flex-basis: 50%;
    padding: 3rem;
    padding-right: 5vw;
    transform: translate( -5vw, -5rem );
  }

  @media all and ( min-width: 1150px ) {
    flex-basis: 33.33%;
  }

  p {
    margin-bottom: 1em;
  }

  p:first-child {
    ${ typo.medium }
    font-weight: 300;
  }
`