import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import history from 'carbonlite/history'
import { typo } from 'carbonlite/styles/fonts'
import Icon from './Icon/Icon'

const navBreakpoint = 900;

const Nav = ( { location: { pathname } } ) => {
  const [isMobileOpen, setMobileOpen] = useState( false );

  const onBurgerClick = () => {
    setMobileOpen( !isMobileOpen )
  }

  history.listen( location =>  {
    setMobileOpen( false )
  } )

  return (
    <StyledNav>
      <StyledNavUnderlay
        isOpen={ isMobileOpen }
        onClick={ () => setMobileOpen( false ) }
      />
      <Burger onClick={ onBurgerClick } isOpen={ isMobileOpen } />
      <StyledNavLinksWrapper isOpen={ isMobileOpen }>
        <NavLink to="/" active={ pathname === '/' }>Home</NavLink>
        <NavLink to="/products" active={ pathname.startsWith( '/products' ) }>Products</NavLink>
        <NavLink to="/applications" active={ pathname.startsWith( '/applications' ) }>Applications</NavLink>
        <NavLink to="/process-development" active={ pathname.startsWith( '/process-development' ) }>Process Development</NavLink>
        <NavLink to="/contact" active={ pathname === '/contact' }>Contact</NavLink>
      </StyledNavLinksWrapper>
    </StyledNav>
  )
}

const StyledNav = styled.div`
  position: relative;
  height: 100%;

  /* @media all and ( max-width: ${ navBreakpoint }px ) {
    border-bottom: 1px solid rgba( var( --footerBgRgb ), 0.3 );
  } */
`

const StyledNavLinksWrapper = styled.div`
  @media all and ( min-width: ${ navBreakpoint + 1 }px ) {
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media all and ( max-width: ${ navBreakpoint }px ) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 10rem;
    padding: 7rem 5vw 3rem 10vw;
    text-align: right;
    transform: translateX( 101% );
    transition:
      transform .3s ease-out,
      background-color 2s ease-in;
    background-color: var( --red );

    ${ p => p.isOpen && css`
      transform: translateX( 0 );
    ` }
  }
`

const StyledNavUnderlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  right: 0;

  ${ p => p.isOpen && css`
    left: 0;
  ` }
`

const Burger = ( { onClick, isOpen } ) => {
  return (
    <StyledBurger onClick={ onClick } isOpen={ isOpen }>
      <Icon iconName="burger" />
    </StyledBurger>
  )
}

const StyledBurger = styled.div`
  z-index: 2;
  position: absolute;
  right: 5vw;
  top: 28px;
  width: 23px;
  cursor: pointer;

  @media all and ( min-width: ${ navBreakpoint + 1 }px ) {
    display: none;
  }

  svg rect {
    fill: var( --black );
    transition:
      transform .3s ease-in-out,
      fill .3s ease-out;
  }

  ${ p => p.isOpen && css`
    svg rect {
      fill: white;

      &:nth-child(2) {
        transform: translateX( 20% );
      }

      &:nth-child(3) {
        transform: translateX( 60% );
      }

      &:nth-child(4) {
        transform: translateX( 40% );
      }
    }
  ` }

  &:hover {
    svg rect {
      &:nth-child(2) {
        transform: translateX( 60% );
      }

      &:nth-child(3) {
        transform: translateX( 40% );
      }

      &:nth-child(4) {
        transform: translateX( 20% );
      }
    }
  }
`

const NavLink = ( { to, active, children } ) => {
  if ( /^https?:\/\/|^\/\//i.test( to ) ) {
    return <StyledNavA href={ to } active={ active }>{ children }</StyledNavA>
  }

  return <StyledNavLink to={ to } active={ active }>{ children }</StyledNavLink>
}

const NavLinkStyles = css`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var( --darkBlue );
  border-left: 1px solid rgba( var( --footerBgRgb ), 0.3 );
  border-bottom: 1px solid rgba( var( --footerBgRgb ), 0.3 );
  transition:
    color .3s ease-out,
    background-color .5s ease-out,
    border-color .5s ease-out;

  &:first-child {
    border-left: 0;

    &:after {
      left: 0px;
    }
  }

  ${ p => p.active && css`
    background-color: var( --red ) !important;
    border-color: var( --red ) !important;
    color: var( --bgGrey );

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      right: 0;
      bottom: -6px;
      height: 3px;
      background: var( --darkBlue );
    }
  ` }

  @media all and ( min-width: ${ navBreakpoint + 1 }px ) {
    flex-grow: 1;
    height: 100%;
    line-height: 75px;
    padding-top: 5px;
    text-align: center;

    &:hover {
      background-color: var( --darkBlue );
      border-color: var( --darkBlue );
      color: var( --bgGrey );
    }
  }

  @media all and ( max-width: ${ navBreakpoint }px ) {
    display: block;
    padding-bottom: 4px;
    margin-bottom: 1em;
    border-color: transparent;
    transition: border-color .3s ease-out;
    text-align: right;
    ${ typo.medium }
    color: white;

    &:after {
      background: white;
    }
  }
`

const StyledNavLink = styled( ( { active, ...rest } ) => <Link { ...rest } /> )`
  ${ NavLinkStyles }
`

const StyledNavA = styled( ( { active, ...rest } ) => <a { ...rest } /> )`
  ${ NavLinkStyles }
`

export default withRouter( Nav )