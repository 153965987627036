const products = {
  title: 'Products',
  intro: `Our products`,
  products: [
    {
      slug: 'coating-and-laminating',
      title: 'Coating and Laminating Machines',
      intro: 'Carbonlite modular coating and laminating machines for 300mm, 400mm and 600mm web widths draws web off the parent reel, applies wet coating to one side of the web, dries/cures the coating, optionally laminates a second web to the parent web, and rewinds the product web.',
      img: '/images/machine-2s.jpg',
      lists: [
        {
          title: 'Features',
          items: [
            'Precision, rigid back plate structure, all machined attachment points',
            'All servo web transport system with web guiding',
            'Wide choice of cartridge type coating head types',
            'Moduar high velocity hot hair dryers, IR supplementary option',
            'Heated, pneumatically operated laminating nip',
          ],
        },
        {
          title: 'Optional',
          items: [
            'Interleave rewind shafts',
            'UV embossing module',
            'Flexo printing head',
            'Corona/Plasma treatment',
            'Web cleaning/antistatic',
            'Chill roller',
          ],
        },
      ],
    },
    {
      slug: 'vacuum-web-coating',
      title: 'Vacuum Web Coating and Metalizing',
      intro: 'Carbonlite re-configurable vacuum web coating machines for 150mm and 400mm web widths.',
      img: '/images/machine-5s.jpg',
      lists: [
        {
          title: 'Features',
          items: [
            'Space frame chamber with full web width bolted covers allowing rapid configuration for a complete range of coating methods',
            'Integral web transport system on an interchangeable back plate',
            'Full 360 degree chamber access via sliding back plate and front hinged door',
            'Vacuum pumping uniformity optimized by external mounting of wire feeds',
          ],
        },
        {
          title: 'Optional',
          items: [
            'Interleave rewind shafts',
            'UV embossing module',
            'Flexo printing head',
            'Corona/Plasma treatment',
            'Web cleaning/antistatic',
            'Chill roller',
          ],
        },
      ],
    },
    {
      slug: 'uv-embossing',
      title: 'UV Embossing',
      intro: 'Carbonlite modular UV embossing machines for 300mm, 400m and 600mm web widths, draws web off the parent reel applies UV curable lacquer to one side of the web, replicates the micro structure from master cylinder into the lacquer, cures the lacquer, optionally laminates a second web to the parent web and rewinds the product web.',
      img: '/images/machine-1s.jpg',
      lists: [
        {
          title: 'Features',
          items: [
            'Structure size down to 200 nano neters successfully processed',
            'Precision, rigid back plate structure, all machined attachment points',
            'All servo web transport system with web guiding',
            'Web cleaning and antistatic',
            'Corona treatment',
            'Wide choice of cartridge type coating head types',
            'Cartridge type embossing head with integrated cooling',
            'Pneumating rise and fall lamp mounting',
            'Heated, pneumatically operated laminating nip',
          ],
        },
        {
          title: 'Optional',
          items: [
            'Interleave rewind shafts',
            'Flexo printing head',
            'Chill roller',
          ],
        },
      ],
    },
    {
      slug: 'coated-structured-films',
      title: 'Coated Structured Films',
      intro: 'Carbonlite can supply coated or uncoated films from our 300mm combined wet coating and UV embossing machine',
      img: '/images/structured-film.jpg',
      lists: [
        {
          title: 'Features',
          items: [
            'Evaporative coatings can be applied in our 300mm vacuum coating machine',
            'Moth eye',
            'Micro lens arrays',
            'Frensel lens arrays',
            'Gratings',
            'Carbonlite can supply embossing rollers originated to customer specific geometries',
          ],
        },
      ],
    },
    {
      slug: 'flexible-solar-cells',
      title: 'Flexible Photo Voltaic (Solar Cells)',
      intro: 'Carbonlite has developed a unique photo voltaic cell geometry designed for low cost rapid mass manufacturing of solar cell modules on polymer substrates.',
      img: '/images/solar10.jpg',
      lists: [
        {
          title: 'Information',
          items: [
            'Manufacturing equipment required is a Carbonlite 400mm coating and laminating machine and a Carbonlite 400mm vacuum coating machine.',
            'The machines are supplied as part of a complete technology transfer project; each module is capable of producing the equivalent of 60 megawatts a year with a foot print of 400 square meters.',
          ],
        },
      ],
    },
  ],
}

export default products