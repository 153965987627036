import React from 'react'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Head from './Head'
import LargeCopy from './LargeCopy'
import Button from './Button'
import {
  StyledPanel,
  StyledContentPanel,
  StyledBulletList,
  StyledPanelImage,
} from './Panel'
import carbonlite from 'carbonlite/data/carbonlite'

const Page = ( { page } ) => (
  <>
    <Head
      title={ `${ page.title } — Carbonlite Converting Equipment Ltd` }
      description={ page.intro }
    />

    <LayoutSection fixedHeaderPush narrow>
      <VerticalStack large>
        <LargeCopy>{ page.title }</LargeCopy>
      </VerticalStack>
    </LayoutSection>

    <StyledPanel>
      <StyledContentPanel>
        <p>{ page.intro }</p>

        { page.lists.map( ( list, i ) => (
          <React.Fragment key={ i }>
            { list.title && <h3>{ list.title }</h3> }
            { list.textBlock && <p>{ list.textBlock }</p> }
            { list.items && (
              <StyledBulletList>
                { list.items.map( ( item, i ) => <li key={ i }>{ item }</li> ) }
              </StyledBulletList>
            ) }
          </React.Fragment>
        ) ) }

        <Button icon="email" to={ `mailto:${ carbonlite.email }` }>Email Us</Button>
      </StyledContentPanel>

      <StyledPanelImage>
        { page.img && <img src={ page.img } alt="" /> }
      </StyledPanelImage>
    </StyledPanel>
  </>
)

export default Page