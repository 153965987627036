// @flow
import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'
import body from './body'
import fonts from './fonts'
import colors from './colors'

const extractRGB = ( str ) => {
  var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  return match ? {
    red: match[1],
    green: match[2],
    blue: match[3]
  } : {};
}

const BaseStyles = createGlobalStyle`
  ${ reset }
  ${ fonts }
  ${ colors }
  ${ body }

  ${ p => {
    if ( !p.highlightColors || !p.highlightColors.length ) {
      return null
    }

    const color = p.highlightColors[ Math.floor( Math.random() * p.highlightColors.length ) ]
    const rgb = extractRGB( color )

    return css`
      :root {
        --highlightColor: ${ color };
        --highlightColorRgb: ${ rgb.red }, ${ rgb.green }, ${ rgb.blue };
      }
    `
  } }
`

export default BaseStyles
