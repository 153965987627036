/* global module, process */
import React from 'react'
import { hydrate, render } from 'react-dom'
import AppWithRouter from 'carbonlite/components/AppWithRouter'
const renderer = ( window.APP_SSR ) ? hydrate : render

renderer(
  <AppWithRouter />,
  document.getElementById( 'root' ),
)
