import React from 'react'
import nl2br from 'react-nl2br'
import carbonlite from 'carbonlite/data/carbonlite'

const contact = {
  title: 'Contact Us',
  intro: 'Located in the Lancashire town of Littleborough. An area with a strong manufacturing tradition and a history of paper manufacture printing and converting. We are easy to get to, being only 5 minutes from the main motorway network of the M60 and M62.',
  // img: '/images/console-1.jpg',
  lists: [
    {
      title: null,
      textBlock: 'Contact our sales department for all your requirements, from new machines to meet your converting and embossing needs to modifications and upgrades to your existing equipment, to new airshafts and to spares and repairs on your existing airshafts.',
    },
    {
      title: 'Telephone',
      textBlock: <a href={ `tel:${ carbonlite.phone }` }>{ carbonlite.phone }</a>,
    },
    {
      title: 'Email',
      textBlock: <a href={ `mailto:${ carbonlite.email }` }>{ carbonlite.email }</a>,
    },
    {
      title: 'Address',
      textBlock: nl2br( carbonlite.address ),
    },
  ],
}

export default contact