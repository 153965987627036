// @flow
import { css } from 'styled-components'

const colors = css`
  :root {
    --bgGreyRgb: 237, 239, 241;
    --footerBgRgb: 147, 168, 172;
    --blackRgb: 29, 29, 27;

    --midBlueRgb: 85, 189, 202;
    --vibrantBlueRgb: 61, 238, 231;
    /* --vibrantBlueRgb: 97, 224, 255; */
    /* --vibrantBlueRgb: 6, 172, 172; */
    --yaleBlueRgb: 22, 76, 163;
    --darkBlueRgb: 23, 52, 56;
    --redRgb: 252, 69, 68;

    --bgGrey: rgb( var( --bgGreyRgb ) );
    --footerBg: rgb( var( --footerBgRgb ) );
    --black: rgb( var( --blackRgb ) );

    --midBlue: rgb( var( --midBlueRgb ) );
    --vibrantBlue: rgb( var( --vibrantBlueRgb ) );
    --yaleBlue: rgb( var( --yaleBlueRgb ) );
    --darkBlue: rgb( var( --darkBlueRgb ) );
    --red: rgb( var( --redRgb ) );
  }
`

export default colors
