import React from 'react'
import styled from 'styled-components'
// import LayoutSection from './LayoutSection'
import Nav from './Nav'
import Logo from './Logo'

const Header = () => {
  return (
    <StyledHeader>
        <StyledLogoLayout>
          <Logo />
        </StyledLogoLayout>
        <StyledNavLayout>
          <Nav />
        </StyledNavLayout>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: var( --bgGrey );
  display: flex;
`

const StyledLogoLayout = styled.div`
  background: var( --vibrantBlue );
  padding: 25px 2rem 25px 5vw;
`

const StyledNavLayout = styled.div`
  flex-grow: 1;
  /* margin-top: 42px; */
  /* margin-left: auto; */
`

export default Header
