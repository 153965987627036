import React from 'react'
import styled, { css } from 'styled-components'

const LayoutSection = styled.div`
  box-sizing: content-box;
  padding-left: 5vw;
  padding-right: 5vw;

  ${ p => p.centered && css`
    max-width: 1500px;
    margin: 0 auto;
  ` }

  ${ p => p.fixedHeaderPush && css`
    padding-top: 82px;
    margin-top: 50px;

    @media all and ( min-width: 900px ) {
      margin-top: 80px;
    }
  ` }

  ${ p => p.narrow && css`
    @media all and ( min-width: 900px ) {
      max-width: 600px;
    }
  ` }

  ${ p => p.pushLeft && css`
    @media all and ( min-width: 900px ) {
      margin-right: 5vw;
    }
  ` }

  ${ p => p.white && css`
    background: white;
  ` }
`

export default LayoutSection
