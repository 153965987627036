import React from 'react'
import styled from 'styled-components'
import { typo } from 'carbonlite/styles/fonts'

export const StyledPanel = styled.div`
  position: relative;

  @media all and ( min-width: 901px ) and ( max-width: 1149px ) {
    margin-top: 2rem;
  }

  @media all and ( min-width: 901px ) {
    display: flex;
  }
`

export const StyledContentPanel = styled.div`
  flex-basis: 66.66%;
  flex-grow: 1;
  padding: 3rem 10vw 5rem 5vw;
  background: white;

  @media all and ( min-width: 1150px ) {
    flex-basis: 66.66%;
  }

  > p {
    margin-bottom: 1em;

    &:first-of-type {
      ${ typo.medium }
      font-weight: 300;
    }
  }

  > p + h3,
  > p + ul,
  > p + div {
    margin-top: 2rem;
  }

  h3 {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: var( --red );
    border-bottom: 1px solid var( --red );
  }

  > p a,
  > li a {
    display: inline-block;
    color: var( --darkBlue );
    text-decoration: none;
    border-bottom: 1px solid rgba( var( --darkBlueRgb ), 0.3 );
  }
`

export const StyledBulletList = styled.ul`
  list-style: none;
  margin-bottom: 3rem;

  li {
    padding: 1em 0;
    border-bottom: 1px solid rgba( var( --footerBgRgb ), 0.3 );
  }
`

export const StyledPanelImage = styled.div`
  flex-grow: 1;

  @media all and ( min-width: 900px ) {
    flex-basis: calc( 33.33% + 5vw );
    margin-right: -5vw;
    transform: translate( -5vw, 3rem );
  }

  @media all and ( min-width: 1150px ) {
    transform: translate( -5vw, -5rem );
  }

  img {
    display: block;
    max-width: 100%;
    /* filter: grayscale( 100% ); */
  }
`