import React from 'react'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Head from './Head'
import LargeCopy from './LargeCopy'

const NotFound = ( { staticContext = { status: 200 } } ) => {
  staticContext.status = 404

  return (
    <>
      <Head
        title="Page Not Found — Carbonlite Converting Equipment Ltd"
      />
      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <LargeCopy>Page not found.</LargeCopy>
        </VerticalStack>
      </LayoutSection>
    </>
  )
}

export default NotFound