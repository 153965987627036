import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Icon from './Icon/Icon'

const Button = ( {
  icon,
  iconPosition,
  to = null,
  children,
} ) => {
  const iconTransition = icon === 'arrowRight'
    ? 'translateX( 8px )'
    : null

  if ( to ) {
    if ( /^(https?:\/\/|^\/\/|mailto:?)/i.test( to ) ) {
      return (
        <StyledButton
          iconPosition={ iconPosition }
          iconTransition={ iconTransition }
        >
          <a href={ to }>
            <Icon iconName={ icon } />
            { children }
          </a>
        </StyledButton>
      )
    }
    else {
      return (
        <StyledButton
          iconPosition={ iconPosition }
          iconTransition={ iconTransition }
        >
          <Link to={ to }>
            <Icon iconName={ icon } />
            { children }
          </Link>
        </StyledButton>
      )
    }
  }


  return (
    <StyledButton
      iconPosition={ iconPosition }
      iconTransition={ iconTransition }
    >
      <div>
        <Icon iconName={ icon } />
        { children }
      </div>
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.div`
  display: inline-block;

  > * {
    display: block;
    padding: 1rem 1.6rem;
    background: var( --red );
    color: white;
    transition: background-color .3s ease-out;
    text-decoration: none;
  }

  div {
    display: flex;
    align-items: center;
  }

  svg {
    display: inline;
    height: 15px;
    fill: white;
    margin-right: 0.6rem;
    transition: transform .3s ease-out;
  }

  ${ p => p.iconPosition === 'right' && css`
    div {
      flex-direction: row-reverse;
    }

    svg {
      margin-right: 0;
      margin-left: 0.6rem;
    }
  ` }

  a:hover &,
  &:hover {
    > * {
      background: var( --darkBlue );

      svg {
        ${ p => p.iconTransition && css`
          transform: ${ p.iconTransition };
        ` }
      }
    }
  }
`