// @flow
import { css } from 'styled-components'
import colors from './colors'

export const typo = {
  body: css`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.36;
  `,
  heading: css`
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.25;
  `,
  logo: css`
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
  `,

  large: css`
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 400;
    color: var( --headlineGrey );

    @media all and ( min-width: 600px ) {
      font-size: 32px;
    }
  `,

  medium: css`
    font-family: 'Roboto', sans-serif;
    font-size: 21px;
    line-height: 1.2;
    font-weight: 400;
    color: var( --headlineGrey );

    @media all and ( min-width: 600px ) {
      font-size: 24px;
    }
  `,
}

const fonts = css`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700|Roboto:300,300i,400,400i,500,500i,700,700i|Varela+Round');

  html,
  body {
    ${ typo.body }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${ colors.darkBlue };
  }
`

export default fonts
