import React from 'react'
import { Router } from 'react-router-dom'
import history from 'carbonlite/history'
// import apolloClient from 'carbonlite/apolloClient'
import AppContainer from 'carbonlite/components/AppContainer'

const AppWithRouter = () => (
  <Router history={ history }>
    {/* <AppContainer apolloClient={ apolloClient } /> */}
    <AppContainer />
  </Router>
)

export default AppWithRouter
