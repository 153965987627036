import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollToTop = ( { location: { pathname, search } } ) => {
  if ( typeof window !== 'undefined' ) {
    useEffect( () => {
      window.scrollTo( 0, 0 )
    }, [pathname, search] )
  }

  return null
}

export default withRouter( ScrollToTop );