import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { typo } from 'carbonlite/styles/fonts'

const Logo = ( { withTag = false, light = false } ) => {
  return (
    <StyledLogoLink to="/">
      <StyledLogo light={ light }>
        carbon<span>lite</span>
      </StyledLogo>
      { withTag && <StyledTag light={ light }>converting equipment ltd</StyledTag> }
    </StyledLogoLink>
  )
}

const StyledLogoLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const StyledLogo = styled.h1`
  ${ typo.logo }
  font-size: 32px;
  color: var( --darkBlue );

  span {
    color: var( --bgGrey );
  }

  ${ p => p.light && css`
    color: var( --bgGrey );
  ` }
`

const StyledTag = styled.h2`
  ${ typo.logo }
  font-size: 18px;
  color: var( --darkBlue );
`

export default Logo
