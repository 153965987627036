import React from 'react'
import { hot } from 'react-hot-loader'
import { withRouter } from 'react-router-dom'
// import { ApolloProvider } from 'react-apollo'
import usePageTracker from 'carbonlite/hooks/usePageTracker'
import ScrollToTop from './ScrollToTop'
// import AppWithData from './AppWithData'
import App from './App'

const AppWithApollo = ( { apolloClient, location } ) => {
  usePageTracker()

  return (
    // <ApolloProvider client={ apolloClient }>
    <div>
      <ScrollToTop />
      {/* <AppWithData /> */}
      <App />
    </div>
    // </ApolloProvider>
  )
}

export default hot( module )( withRouter( AppWithApollo ) )
